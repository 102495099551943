import React from "react"

import { navigate } from 'gatsby'
import { Button, Col, Image, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import communityImg from "../images/system-explained/community.png"
import coopImg from "../images/system-explained/coop.png"
import dataCollectImg from "../images/system-explained/datacollecting.png"
import driverImg from "../images/system-explained/driver.png"
import marshalImg from "../images/system-explained/marshal.png"
import raceControlImg from "../images/system-explained/racecontrol.png"
import tsaidImg from "../images/system-explained/tsaid.svg"
import driverIcon from "../images/system-explained/driverIcon.svg"
import marshalIcon from "../images/system-explained/marshalIcon.svg"
import communityIcon from "../images/system-explained/communityIcon.svg"
import coopIcon from "../images/system-explained/coopIcon.svg"
import rcIcon from "../images/system-explained/rcIcon.svg"
import { FaFileExcel } from "react-icons/fa"

const RaceTrackPage = () => (
  <Layout pageInfo={{ pageName: "racetrack" }}>
    <Seo title="Das TrackSecure System" keywords={[`System`, `TrackSecure`, `Driver App`, `Marshal`, `RaceControl`]} />
    <h1 class="heading-4">Ihre Komponenten für mehr Sicherheit</h1>
    <Row>
      <Col>
        <div class="m-3">
          <b><i>JEDE STRECKE IST ANDERS</i></b>. Sei es aufgrund ihrer Topografie,
          den Gegebenheiten um die Strecke, der Zielgruppe an Fahrern oder den bereits bestehenden Sicherheitsmaßnahmen vor Ort.<br />
          Daher kann jeder Streckenbetreiber selbst entscheiden, welche unserer sechs Komponenten wie stark in die Abläufe auf der Strecke eingebunden werden sollen.
        </div>
        <div class="m-3">
          Unsere Lösungen sind für permanente Rennstrecken genauso geeignet wie für "Stadtkurs" und Rallystrecke. Einmal in unser System eingepflegt, kann es direkt losgehen.
        </div>
      </Col>
    </Row>
    <Row>
      <Col className="racetrack-feature-image trackday p-3">
        <StaticImage src="../images/system-explained/in-a-nutshell.png" alt="tracksecure in a nutshell" />
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          Unser Ziel ist es, mehr Sicherheit bei Motorsportveranstaltungen zu schaffen, ohne aufwändige Hardware an der Strecke installieren zu müssen.
          Dazu greifen wir auf die sechs Komponenten unseres TrackSecure Systems zurück.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <div class="racetrack-icon"><Image src={driverIcon} /></div>
          <div class="racetrack-feature">DRIVER</div>
          <div >
            In der Driver Sicht erhält der Fahrer eine Übersicht der Flaggensignale auf der Strecke.
            Die Flaggensignale in der Liste sind für jeden Fahrer individuell nach Entfernung sortiert. </div><br />
          <div >
            Dem Fahrer wird die aktuelle Entfernung des nächst gelegenen Flaggensignals  in Metern angezeigt.
            Kommt er in den Gefahrenbereich, so wird er durch ein aktiv blinkendes Display gewarnt
          </div><br />
        </div>
      </Col>
      <Col className="racetrack-feature-image">
        <StaticImage src="../images/system-explained/driver.png" />
      </Col>
    </Row>
    <Row>
      <Col className="racetrack-feature-image">
        <StaticImage src="../images/system-explained/marshal.png" />
      </Col>
      <Col>
        <div class="m-3">
          <div class="racetrack-icon"><Image style={style.icons} src={marshalIcon} /></div>
          <div class="racetrack-feature">MARSHAL</div>
          <div >
            Die zentrale Funktionalität für den Marshal ist das Senden der Flaggensignale für seinen Streckensektor zur RaceControl. </div><br />
          <div >
            Der Marshal wählt dazu einmalig seinen Posten an der Strecke aus. Alle gesendeten Flaggensignale werden automatisch dem gewählten  Posten zugeordnet.
          </div><br />
          <div >
            Für jeden Signaltyp kann der Marshal per “Instant-Signal”-Knopf direkt das Flaggensignal auslösen. Es wird in Echtzeit an die Rennleitung und wenn gewünscht an alle Fahrer übertragen.
          </div><br />
          <div >
            Außerdem kann sich der Marshal alle Incidents auf der gesamten Strecke anzeigen lassen.
          </div><br />
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <div class="racetrack-icon"><Image style={style.icons} src={communityIcon} /></div>
          <div class="racetrack-feature">COMMUNITY</div>
          <div >
            Über den Community-Ansatz ermöglichen wir es jedem Teilnehmer, aktiv zur Absicherung der Strecke beizutragen. </div><br />
          <div >
            Erfahrene Fahrer haben häufig ein gutes Gefühl für die Beschaffenheit der Strecke. Sie “spüren” gefährliche Situationen, die man mit bloßem Auge oder Kameras möglicherweise nicht sehen kann (z.B. Gripverlust duch Benzinspuren) und können dann aktiv an die RaceControl eine potentielle Gefahr melden.
          </div><br />
          <div >
            Neben der Meldung über den Knopf im Display, gibt es die Möglichkeit, einen Hardware-Puck zu koppeln, der sich auch verlässlich mit Handschuhen nutzen lässt.
          </div><br />
        </div>
      </Col>
      <Col className="racetrack-feature-image">
        <StaticImage src="../images/system-explained/community.png" />
      </Col>
    </Row>
    <Row>
      <Col className="racetrack-feature-image">
        <StaticImage src="../images/system-explained/coop.png" />
      </Col>
      <Col>
        <div class="m-3">
          <div class="racetrack-icon"><Image style={style.icons} src={coopIcon} /></div>
          <div class="racetrack-feature">INTEGRATION</div>
          <div >
            Über unsere Integrationsmodule können Bestandssysteme der Strecke mit TrackSecure gekoppelt werden. Hierzu werden von uns in enger Abstimmung mit dem Strckenbetreiber individuell passende Adapter geschaffen.
          </div><br />
          <div >
            So können beispielsweise Flaggensignale in beide Richtungen ausgetauscht werden. TrackSecure kann direkten Einfluss auf bereits installierte Ampelsysteme bzw. Warntafeln nehmen, oder die Signale der Rennleitung abgreifen und an alle Teilnehmer aussteuern.
          </div><br />
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <div class="racetrack-icon"><Image style={style.icons} src={rcIcon} /></div>
          <div class="racetrack-feature">RACE CONTROL</div>
          <div >
            In der RaceControl laufen alle ausgelösten Flaggensignale zusammen.

            Nutzer der RaceControl können entscheiden, ob ein Signal tatsächlich valide ist, und “scharf geschaltet” werden soll oder nicht.

            Die RaceControl kann das Signal außerdem anpassen (genauer spezifizieren), korrigieren und selbst Signale auslösen. </div><br />
          <div >
            Im RaceControl Monitor sehen die Nutzer alle auf der Strecke befindlichen Fahrzeuge mit erweiterten Infos (z.B. Geschwindigkeit) und aktuelle Flaggensignale.

            So lässt sich auch ohne Kameras das Geschehen auf der Strecke überwachen.
          </div>
        </div>
      </Col>
      <Col className="racetrack-feature-image">
        <StaticImage src="../images/system-explained/racecontrol.png" />
      </Col>
    </Row>
    <Row>
      <Col className="racetrack-feature-image">
        <StaticImage src="../images/system-explained/datacollecting.png" />
      </Col>      
      <Col>
        <div class="m-3">
          <div class="racetrack-icon"><Image style={style.images} src={tsaidImg} /></div>
          <div class="racetrack-feature">AUTOMATED INCIDENT DETECTION</div>
          <div >
            Die Automated Incident Detection sammelt relevante Daten jedes Fahrzeugs auf der Strecke. Diese Daten werden in Echtzeit ausgewertet.
          </div><br />
          <div >
            Daraus werden potentielle Gefahren errechnet (z.B. verunfallte Fahrzeuge) und an die RaceControl gemeldet oder je nach Konfigruation auch direkt an alle Teilnehmer übermittelt.
          </div><br />
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <p class="trackday-headline"><b>Sie haben Interesse, TrackSecure auf ihrer Strecke einzusetzen?</b></p>
          Konktaktieren Sie uns gerne.
        </div>
      </Col>
      <Col className="m-3 racetrack-button">
        <Button variant="dark" onClick={() => navigate('/contact')}>KONTAKT AUFNEHMEN</Button>
      </Col>
    </Row>

  </Layout>
)

let style = {
  images: {
    height: 50
  },
  icons: {
    height: 100
  },
  nutshell: {
    height: 400,
    margin: 20,
  },
  wideimg: {
    width: 400,
    margin: 20,
  },
  dsk: {
    height: 100,
    marginBottom: 80,
    marginTop: 100
  },
  dsktext: {
    marginTop: 100
  }
}

export default RaceTrackPage
